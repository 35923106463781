import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import { Modal, Button } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import { FaWhatsapp, FaHome, FaCarSide } from "react-icons/fa";
import { FirebaseContext } from "gatsby-plugin-firebase";

const from = "homeEquity";

function ContactModal(props) {
  const firebase = React.useContext(FirebaseContext);

  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [pagination, setPagination] = useState(1)
  const [formValid, setFormValid] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [dataToSend, setDataToSend] = useState({
    info: "",
    contato: false,
    atribuidoEm: "",
    parceiro: ""
  });

  function addContact() {
    console.log(dataToSend);
    firebase
      .firestore()
      .collection("home.equity")
      .add(dataToSend);
  }

  const handleInput = event => {
    if (event.target.name == 'nome') {
      setNome(event.target.value)
    } else {
      setEmail(event.target.value);
    }
    handleForm(nome, email)
  };

  const handleDataToSend = () => {
    setDataToSend({
      info: email,
      contato: false,
      atribuidoEm: new Date(),
      parceiro: "EMVMzy5IUFS9ECYwuT3SJVSvA953"
    });
  };

  const handleClose = (func) => {
    setPagination(1)
    func()
  }

  const handlePagination = (event) => {
    setPagination(2)
    submit(event)
  }

  const handleForm = (name, tel) => {
    console.log(tel)
    if (name.length > 2 && tel.length > 10) {
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }

  const checkFormValid = data => {
    if (data.email.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const submit = event => {
    // console.log("Funcao Submit");
    event.preventDefault();
    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      "https://zj6psiu5t9.execute-api.us-east-1.amazonaws.com/dev",
      true
    );

    xhr.setRequestHeader("Content-type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
      }
    };
    var data = {
      name: nome,
      email: email,
      message: "",
      pageName: "Home Equity",
      from: from
    };

    if (checkFormValid(data)) {
      xhr.send(JSON.stringify(data));
      addContact();
      setFeedback(true);
      console.log("Contato enviado");
    } else {
      console.log("Erro ao enviar contato");
    }
  };

  return (
    <Modal {...props} centered>
      <Modal.Body closebutton="true">
        <button className="close" onClick={() => handleClose(props.onHide)}>
          <IoIosClose color="#00205B" size={24} />
        </button>
        <div className="m-xl-4 m-3">
          <div className="text-center mb-3">
            <h3 className="mb-0">Faça uma simulação</h3>
            {/* <span className="small mb-2">Entre em contato conosco</span> */}
          </div>
          <hr className="mb-2" />
          <form className="mb-2">
            <div className="form-row">
              <div className="col-12">
                <input type="text" name="_gotcha" style={{ display: "none" }} />
                <input
                  className="form-control mb-2"
                  name="nome"
                  placeholder="Nome"
                  value={nome}
                  onBlur={handleDataToSend}
                  onChange={handleInput}
                />
                <input type="text" name="_gotcha" style={{ display: "none" }} />
                <MaskedInput
                  mask={[
                    "(",
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  className="form-control mb-2"
                  name="email"
                  placeholder="Telefone"
                  guide={false}
                  value={email}
                  onBlur={handleDataToSend}
                  onChange={handleInput}
                />
                {pagination == 1 ?
                  <button
                    type="submit"
                    className={formValid ? "btn btn-primary btn-block" : "btn btn-block btn-disabled"}
                    onClick={handlePagination}
                    disabled={!formValid}
                  >
                    <span>Simule Agora</span>
                  </button>
                  :
                  <div className="text-center mb-3">
                    <span className="small mb-2">Escolha uma das opções abaixo para fazer uma simulação.</span>
                    <span className="small mb-2"><br/>Ao clicar você concorda com nosso LINK.</span>
                  </div>
                }
              </div>
            </div>
          </form>
          <div className="text-center mb-4">
            {pagination == 1 ?
              <a
                className="btn btn-whatsapp btn-block"
                href="https://api.whatsapp.com/send?phone=5561982434736&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20o%20empr%C3%A9stimo%20com%20garantia."
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp color="#fbfbfb" className="mr-2 mb-1" size={18} />
              Mande mensagem no WhatsApp
            </a>
              :
              <div className="modal-wrapper">
                <a className="modal-type-container btn" href="https://app.creditas.com/home-equity/?utm_medium=offline&utm_source=CB979" target="_blank">
                  <FaHome color="#00205B" className=" mb-1" size={38} />
                  <p>Casa</p>
                </a>
                <a className="modal-type-container btn" href="https://app.creditas.com/steps/emprestimo/garantia-veiculo/solicitacao/informacoes-pessoais?utm_medium=parcerias&utm_source=CB979" target="_blank">
                  <FaCarSide color="#00205B" className=" mb-1" size={38} />
                  <p>Carro</p>
                </a>
              </div>
            }

          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ContactModal;
