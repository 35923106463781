import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import logo from "../images/logo-wide.png";
import "./header.css";
import ContactModal from "./contactModal";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

function Header() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <Navbar expand="lg">
      <div className="container">
        <Navbar.Brand href="#">
          <img
            src={logo}
            width="auto"
            height="36"
            className="d-inline-block align-top mb-0 header-logo"
            alt="Guerra Finanças e Negócios logotipo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="fix-nav">
          <Nav>
            <Nav.Link className="nav-l" href="#porque">Garantia</Nav.Link>
            <Nav.Link className="nav-l" href="#icones">Vantagens</Nav.Link>
            <Nav.Link className="nav-l" href="#etapas">Aquisição</Nav.Link>
            <Nav.Link className="nav-l" href="#duvidas">Dúvidas</Nav.Link>
            <Nav.Link className="nav-l" href="#footer">Contato</Nav.Link>
            <div className="d-flex ml-lg-3">
              <button
                className="no-mg btn btn-primary"
                onClick={() => setModalShow(true)}
              >
                Simule agora
              </button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </div>
      <ContactModal show={modalShow} onHide={() => setModalShow(false)} />
    </Navbar>
  );
}

export default Header;
