import React from "react";
import { Navbar } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa";
import './preHeader.css';

const PreHeader = () => (
  <Navbar expand="lg" className="pre-nav">
    <div className="container justify-content-center align-content-center">
        <FaWhatsapp color="#25d366" className="pre-icon my-auto mr-2"/>
        <a 
          className="text-black-50 m-0" 
          href="https://api.whatsapp.com/send?phone=5561982434736&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20o%20empr%C3%A9stimo%20com%20garantia."
          target="_blank" rel="noopener noreferrer"
        >
          (61) 8663-2928
        </a>
    </div>
  </Navbar>
);

export default PreHeader;
