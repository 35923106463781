import React, { useState } from "react";
import { FirebaseContext } from "gatsby-plugin-firebase";
import MaskedInput from "react-text-mask";
import {
  FaInstagram,
  FaFacebookSquare,
  FaWhatsapp,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt
} from "react-icons/fa";

import "./footer.css";
import "../../node_modules/sal.js/dist/sal.css";

const from = "homeEquity";

function Footer() {
  const firebase = React.useContext(FirebaseContext);

  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [dataToSend, setDataToSend] = useState({ info: "", contato: false });

  // useFirebase(firebase => {
  //     firebase
  //         .firestore()
  //         .collection('home.equity')
  //         .add(DATA)
  // }, [])

  function addContact() {
    console.log("ADD CONTACT");
    firebase
      .firestore()
      .collection("home.equity")
      .add(dataToSend);
  }

  const handleInput = event => {
    setEmail(event.target.value);
  };

  const handleDataToSend = () => {
    setDataToSend({ info: email, contato: false });
  };

  const submit = event => {
    console.log("Funcao Submit");
    event.preventDefault();
    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      "https://zj6psiu5t9.execute-api.us-east-1.amazonaws.com/dev",
      true
    );

    xhr.setRequestHeader("Content-type", "application/json");
    xhr.onreadystatechange = function() {
      if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
      }
    };
    var data = {
      name: "",
      email: email,
      message: "",
      pageName: "Home Equity",
      from: from
    };

    if (formValid) {
      xhr.send(JSON.stringify(data));
      addContact();
      setFeedback(true);
      setEmail("");
      console.log("Certor");
    } else {
      console.log("Error");
    }
  };

  return (
    <footer id="footer">
      <div className="container">
        
        <div className="row mb-0 mb-lg-3">
          <div className="col-lg-4 mb-4">
            <h2 className="mb-2 text-black">CONTATO</h2>
            <p className="mb-4">
              Ficou interessado?
              <br />
              Deixe seu telefone e entraremos em contato.
            </p>
            <form className="mb-2">
              <div className="form-row">
                <div className="col-12">
                  <input
                    type="text"
                    name="_gotcha"
                    style={{ display: "none" }}
                  />
                  <MaskedInput
                  mask={[
                    "(",
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  className="form-control mb-2"
                  name="email"
                  placeholder="Telefone"
                  guide={false}
                  value={email}
                  onBlur={handleDataToSend}
                  onChange={handleInput}
                />
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    onClick={submit}
                  >
                    <span>Enviar</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-4 mb-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3838.3856368875163!2d-47.9531140487279!3d-15.836316328154425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a2fd9c847c8bd%3A0xccafc412d6d46c5e!2sGuerra%20Cons%C3%B3rcios!5e0!3m2!1spt-BR!2sbr!4v1581516310010!5m2!1spt-BR!2sbr"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: "0", minHeight: "220px" }}
              title="Mapa Guerra Consorcios"
              allowFullScreen=""
            ></iframe>
          </div>
          <div className="col-lg-4 mb-4 font-weight-medium">
            <div className="d-flex mb-3">
              <FaPhone color="#00205B" size={24} />
              <p className="ml-3 mb-2">61 3081-5555 / 61 98243-4736</p>
            </div>
            <div className="d-flex mb-3 flex-wrap">
              <FaEnvelope color="#00205B" size={24} />
              <div className="ml-3">
                <p className="mb-2">guerra@guerraconsorcios.com.br</p>
              </div>
            </div>
            <div className="d-flex mb-3">
              <FaMapMarkerAlt color="#00205B" className="nav-icon" />
              <div className="ml-3">
                <p className="mb-2">
                  The Union Office, Torre E Loja 06
                  <br />
                  SMAS Lote 03, Asa Sul, Brasília - DF
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-2">
          <div className="col-auto">
            <ul className="nav">
              <li className="nav-item">
                <a
                  className="px-3"
                  href="https://www.instagram.com/guerraconsorcios/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram color="#00000080" className="nav-icon" />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3"
                  href="https://www.facebook.com/guerraconsorcios/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookSquare color="#00000080" className="nav-icon" />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3"
                  href="https://api.whatsapp.com/send?phone=5561982434736&text=Tenho%20interesse%20de%20saber%20mais%20sobre%20o%20Home%20Equity!"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp color="#00000080" className="nav-icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col col-md-auto text-center">
            <small className="text-muted">
              ©{new Date().getFullYear()} Todos os direitos reservados. Sede em
              Brasília - DF. Atuação em todo o território nacional - CNPJ
              22.801.094/0001-30
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
